import React from "react";

export default function Rucio() {
  return (
    <div className="embed-responsive embed-responsive-16by9">
      <iframe
        title="rucio"
        className="embed-responsive-item"
        src="https://escape-rucio.cern.ch"
        allowFullScreen
      ></iframe>
    </div>
  );
}
